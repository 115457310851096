export enum TrackingContentName {
  AddButton = "AddButton",
  ApproveButton = "ApproveButton",
  BackButton = "BackButton",
  CancelButton = "CancelButton",
  ConfirmButton = "ConfirmButton",
  ConfirmRefundButton = "ConfirmRefundButton",
  CreateButton = "CreateButton",
  CreateProductButton = "CreateProductButton",
  EditButton = "EditButton",
  EditExistingButton = "EditExistingButton",
  ExportExcelButton = "ExportExcelButton",
  PrintRefundSlipButton = "PrintRefundSlipButton",
  SaveButton = "SaveButton",
  SearchButton = "SearchButton",
  RefreshButton = "RefreshButton",
  RequestRefundButton = "RequestRefundButton",
  ViewDetailButton = "ViewDetailButton",
  UploadHistoryButton = "UploadHistoryButton",
  ViewHistoryButton = "ViewHistoryButton",
  ViewExportHistoryButton = "ViewExportHistoryButton",
  DownloadTemplateButton = "DownloadTemplateButton",
  UploadButton = "UploadButton",
  UploadAnotherFileButton = "UploadAnotherFileButton",
  ViewUploadedFileButton = "ViewUploadedFileButton",
  AddLocationConfigButton = "AddLocationConfigButton",
  ViewErrorDetailButton = "ViewErrorDetailButton",
  ResetFilterButton = "ResetFilterButton",
  MarkShippedButton = "MarkShippedButton",
  RequestCancelButton = "RequestCancelButton",
  ExportButton = "ExportButton",
  ExportEInvoiceButton = "ExportEInvoiceButton",
  SortButton = "SortButton",
  OrderDetailsLink = "OrderDetailsLink",
  PreviousOrderCopyToClipboard = "PreviousOrderCopyToClipboard",
  PageSizeOption = "PageSizeOption",
  CloneButton = "CloneButton",
  OrderPriorityToggle = "OrderPriorityToggle",
  PrintOrderButton = "PrintOrderButton",
  ForceCreateShipmentButton = "ForceCreateShipmentButton",
  CancelToReprocessButton = "CancelToReprocessButton",
  ConfirmToProcessButton = "ConfirmToProcessButton",
  ChangeWarehouseButton = "ChangeWarehouseButton",
  ChangeSiteButton = "ChangeSiteButton",
  ReassignStoreButton = "ReassignStoreButton",
  SwapHoldButton = "SwapHoldButton",
  RequestToCancelButton = "RequestToCancelButton",
  RestockSiteSelect = "RestockSiteSelect",
  MarkAsShippedButton = "MarkAsShippedButton",
  MarkMultiAsShippedButton = "MarkMultiAsShippedButton",
  MarkAsDeliveringButton = "MarkAsDeliveringButton",
  MarkAsPackedButton = "MarkAsPackedButton",
  MarkAsProviderDeliveredButton = "MarkAsProviderDeliveredButton",
  RequestToCancelReturnButton = "RequestToCancelReturnButton",
  CreateBoLButton = "CreateBoLButton",
  ViewBoLDetailButton = "ViewBoLDetailButton",
  PrintLabelButton = "PrintLabelButton",
  CancelRouteButton = "CancelRouteButton",
  EditCustomerButton = "EditCustomerButton",
  AddCustomerButton = "AddCustomerButton",
  EditBillingInfoButton = "EditBillingInfoButton",
  AddBillingInfoButton = "AddBillingInfoButton",
  NewTaxCodeHint = "NewTaxCodeHint",
  EditConsultantButton = "EditConsultantButton",
  AddConsultantButton = "AddConsultantButton",
  ViewDetailTransactionButton = "ViewDetailTransactionButton",
  PreviousOrderLink = "PreviousOrderLink",
  ReplacedByOrderLink = "ReplacedByOrderLink",
  SendButton = "SendButton",
  RejectButton = "RejectButton",
  CloseButton = "CloseButton",
  ViewIncomingStockButton = "ViewIncomingStockButton",
  ViewOrderProblemsButton = "ViewOrderProblemsButton",
  LoadMoreButton = "LoadMoreButton",
  ViewMoreIncomingStockButton = "ViewMoreIncomingStockButton",
  CreatePartialShipmentButton = "CreatePartialShipmentButton",
  UpdateButton = "UpdateButton",
  OrderHistoryTabButton = "OrderHistoryTabButton",
  ReturnInfoTabButton = "ReturnInfoTabButton",
  DeleteButton = "DeleteButton",
  DeleteLocationConfigureButton = "DeleteLocationConfigureButton",
  ViewTerminalsButton = "ViewTerminalsButton",
  ViewMoreInfoButton = "ViewMoreInfoButton",
  HoldingStockButton = "HoldingStockButton",
  WarehouseTreeButton = "WarehouseTreeButton",
  BulkAdjustButton = "BulkAdjustButton",
  MoreActionsButton = "MoreActionsButton",
  ViewApproverButton = "ViewApproverButton",
  SubmitButton = "SubmitButton",
  AddNewInvoiceButton = "AddNewInvoiceButton",
  AgreeButton = "AgreeButton",
  LaterButton = "LaterButton",
  PrintMultipleOrdersBtn = "PrintMultipleOrdersBtn",
  SearchResult = "SearchResult",
  SearchByOrderCodeButton = "SearchByOrderCodeButton",
  ExportOrderListButton = "ExportOrderListButton",
  ExportOrderDetailButton = "ExportOrderDetailButton",
  FilterEnoughStockCheckbox = "FilterEnoughStockCheckbox",
  OrderInfoApplyButton = "OrderInfoApplyButton",
  ContactInfoApplyButton = "ContactInfoApplyButton",
  PaymentInfoApplyButton = "PaymentInfoApplyButton",
  DeliveryInfoApplyButton = "DeliveryInfoApplyButton",
  OrderNoteApplyButton = "OrderNoteApplyButton",
  NotRefundCheckbox = "NotRefundCheckbox",
  AutoEstimateRefundButton = "AutoEstimateRefundButton",
  NotifyCustomerCheckbox = "NotifyCustomerCheckbox",
  ProductInventoryByBatchLink = "ProductInventoryByBatchLink",
  ProductRefLink = "ProductRefLink",
  SiteLeadTimeConfigLink = "SiteLeadTimeConfigLink",
  TerminalDetailLink = "TerminalDetailLink",
  ProductInventoryLink = "ProductInventoryLink",
  BackToOrderListLink = "BackToOrderListLink",
  ActivateButton = "ActivateButton",
  InactivateButton = "InactivateButton",
  ParamTag = "ParamTag",
  ViewParamListButton = "ViewParamListButton",
  CancelOrderButton = "CancelOrderButton",
  DeactivateSiteButton = "DeactivateSiteButton",
  OrderIdInput = "OrderIdInput",
  PhoneNumberInput = "PhoneNumberInput",
  ConsultantIdInput = "ConsultantIdInput",
  StatusSelect = "StatusSelect",
  PlatformSelect = "PlatformSelect",
  TerminalSelect = "TerminalSelect",
  OrderCreatedDateRangeSelect = "OrderCreatedDateRangeSelect",
  DeliveredDateRangeSelect = "DeliveredDateRangeSelect",
  PaymentDateRangeSelect = "PaymentDateRangeSelect",
  ProductSelect = "ProductSelect",
  SellerSelect = "SellerSelect",
  SiteSelect = "SiteSelect",
  ShipmentStatusSelect = "ShipmentStatusSelect",
  OrderStatusSelect = "OrderStatusSelect",
}

export enum TrackingRegionName {
  ConfirmRefundModal = "ConfirmRefundModal",
  ClearanceApprovalButton = "ClearanceApprovalButton",
  CreditApprovalAuthorityCreateDrawer = "CreditApprovalAuthorityCreateDrawer",
  PrintRefundSlipModal = "PrintRefundSlipModal",
  ResetFormModal = "ResetFormModal",
  RefundRequestRecord = "RefundRequestRecord",
  ManualInventoryTrackingRecord = "ManualInventoryTrackingRecord",
  SiteRecord = "SiteRecord",
  ManualInventoryWarehouseRecord = "ManualInventoryWarehouseRecord",
  MarkResolvedButton = "MarkResolvedButton",
  OrderHealthRecord = "OrderHealthRecord",
  ReturnRequestRecord = "ReturnRequestRecord",
  QuotationRecord = "QuotationRecord",
  EditQuotationModal = "EditQuotationModal",
  CancelQuotationModal = "CancelQuotationModal",
  InventoryTrackingByBatchRecord = "InventoryTrackingByBatchRecord",
  DeleteQuotationItemModal = "DeleteQuotationItemModal",
  FundCreateDrawer = "FundCreateDrawer",
  FundEditDrawer = "FundEditDrawer",
  FundTypeCreateDrawer = "FundTypeCreateDrawer",
  FundTypeEditDrawer = "FundTypeEditDrawer",
  ReasonCreateDrawer = "ReasonCreateDrawer",
  ReasonEditDrawer = "ReasonEditDrawer",
  ReturnReasonFormModal = "ReturnReasonFormModal",
  FundsOfUserTab = "FundsOfUserTab",
  UserFundTypeCreateDrawer = "UserFundTypeCreateDrawer ",
  UserFundTypeRecord = "UserFundTypeRecord",
  UserFundTypeEditDrawer = "UserFundTypeEditDrawer",
  UserTerminalCreateDrawer = "UserTerminalCreateDrawer",
  UserTerminalRecord = "UserTerminalRecord",
  CreditApprovalAuthorityRecord = "CreditApprovalAuthorityRecord",
  CreatePurchaseInvoiceModal = "CreatePurchaseInvoiceModal",
  ConfirmGoBackModal = "ConfirmGoBackModal",
  QuickTabs = "QuickTabs",
  OrderActions = "OrderActions",
  OrderPriorityPopup = "OrderPriorityPopup",
  ForceCreateShipmentModal = "ForceCreateShipmentModal",
  OrderItemActions = "OrderItemActions",
  ManualConfirmModal = "ManualConfirmModal",
  BackorderConfirmModal = "BackorderConfirmModal",
  ChangeWarehouseModal = "ChangeWarehouseModal",
  ChangeSiteModal = "ChangeSiteModal",
  ConfirmToProcessModal = "ConfirmToProcessModal",
  AutoCreateSROnChangeSiteModal = "AutoCreateSROnChangeSiteModal",
  AutoCreateSROnConfirmToProcessModal = "AutoCreateSROnConfirmToProcessModal",
  ReassignStoreModal = "ReassignStoreModal",
  SwapHoldModal = "SwapHoldModal",
  ReturnDrawer = "ReturnDrawer",
  ShipmentActions = "ShipmentActions",
  ShipmentTrackingModal = "ShipmentTrackingModal",
  ShipmentStatusManualUpdateModal = "ShipmentStatusManualUpdateModal",
  CreateBoLModal = "CreateBoLModal",
  BoLDetailModal = "BoLDetailModal",
  CancelRoutePopup = "CancelRoutePopup",
  OrderServiceActions = "OrderServiceActions",
  SearchCustomerModal = "SearchCustomerModal",
  CustomerForm = "CustomerForm",
  EditBillingInfoModal = "EditBillingInfoModal",
  OrderComment = "OrderComment",
  ReturnRequestBlock = "ReturnRequestBlock",
  ApproveReturnRequestModal = "ApproveReturnRequestModal",
  RejectReturnRequestModal = "RejectReturnRequestModal",
  CloseReturnRequestModal = "CloseReturnRequestModal",
  SurveyModal = "SurveyModal",
  CreatePartialShipmentModal = "CreatePartialShipmentModal",
  CancelToReprocessModal = "CancelToReprocessModal",
  FundTypeRecord = "FundTypeRecord",
  ReasonRecord = "ReasonRecord",
  FundRecord = "FundRecord",
  FundsTab = "FundsTab",
  FundTypesTab = "FundTypesTab",
  ReasonsTab = "ReasonsTab",
  DeleteButton = "DeleteButton",
  UploadedRecord = "UploadedRecord",
  UserTerminal = "UserTerminal",
  SaveEditingButton = "SaveEditingButton",
  CancelEditingButton = "CancelEditingButton",
  FileUploadPopover = "FileUploadPopover",
  UserFundType = "UserFundType",
  CloneOrderReturnWarningModal = "CloneOrderReturnWarningModal",
  CloneOrderConfirmModal = "CloneOrderConfirmModal",
  NewShipmentConfirmModal = "NewShipmentConfirmModal",
  OrderRecord = "OrderRecord",
  PrintMultipleOrderValidateFailedModal = "PrintMultipleOrderValidateFailedModal",
  SearchStaffModal = "SearchStaffModal",
  WaitForManualConfirmationTab = "WaitForManualConfirmationTab",
  TableColumnFilter = "TableColumnFilter",
  TablePagination = "TablePagination",
  InventoryAlertRecord = "InventoryAlertRecord",
  InventoryHoldRecord = "InventoryHoldRecord",
  ImbalancedStockRecord = "ImbalancedStockRecord",
  UnauthorizedSellerScreen = "UnauthorizedSellerScreen",
  OrderNotificationRecord = "OrderNotificationRecord",
  ParamListModal = "ParamListModal",
  DiscardChangesModal = "DiscardChangesModal",
  ActivateOrderNotificationModal = "ActivateOrderNotificationModal",
  DeleteOrderNotificationModal = "DeleteOrderNotificationModal",
  NPPGiaoHangModal = "NPPGiaoHangModal",
  CancelOrderConfirmModal = "CancelOrderConfirmModal",
  CancelOrderReturnWarningModal = "CancelOrderReturnWarningModal",
  FilterSection = "FilterSection",
  WarrantyActivationFilter = "WarrantyActivationFilter",
}

export enum TrackingTarget {
  CartScreen = "CartScreen",
  PdfPage = "PdfPage",
  DetailTransactionModal = "DetailTransactionModal",
  ViewIncomingStockModal = "ViewIncomingStockModal",
  OrderProblemsModal = "OrderProblemsModal",
  SurveyForm = "SurveyForm",
  ConsumerCartModal = "ConsumerCartModal",
}

export enum TrackingEvent {
  manualTrackInteractionContent = "manualTrackInteractionContent",
  manualTrackVisibleContentImpressions = "manualTrackVisibleContentImpressions",
}

export enum TrackingCheckboxState {
  CHECKED = "checked",
  UNCHECKED = "unchecked",
}
