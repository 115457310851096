import { IPaginationQueryParams } from "./common";

export enum FileProcessingStatus {
  init = "INIT",
  processing = "PROCESSING",
  failed = "FAILED",
  finished = "FINISHED",
}

export enum ImportFileProcessingType {
  IMPORT_ADJUSTMENT = "IMPORT_ADJUSTMENT",
  IMPORT_STOCK = "IMPORT_STOCK",
  IMPORT_BIN = "IMPORT_BIN",
  IMPORT_SITE_INFO = "IMPORT_SITE_INFO",
}

export interface IGetFileProcessingHistoryParams extends IPaginationQueryParams {
  createdBy?: string;
  importType?: ImportFileProcessingType;
  siteId?: number;
}

export enum FileTemplateType {
  IMPORT_ADJUSTMENT = "ADJUSTMENT",
  IMPORT_STOCK = "INIT_STOCK",
  IMPORT_BIN = "IMPORT_BIN",
  IMPORT_SITE_INFO = "IMPORT_SITE_INFO",
}

export enum UploadedFileType {
  IMPORT_ADJUSTMENT = "ADJUSTMENT",
  IMPORT_STOCK = "INIT_STOCK",
  IMPORT_BIN = "IMPORT_BIN",
  IMPORT_SITE_INFO = "IMPORT_SITE_INFO",
  IMPORT_SITE_INFO_ZONE = "IMPORT_SITE_INFO_ZONE",
  IMPORT_SITE_INFO_ROW = "IMPORT_SITE_INFO_ROW",
  IMPORT_SITE_INFO_SHELF = "IMPORT_SITE_INFO_SHELF",
  IMPORT_SITE_INFO_CELL = "IMPORT_SITE_INFO_CELL",
  IMPORT_SITE_INFO_BIN = "IMPORT_SITE_INFO_BIN",
  IMPORT_SITE_INFO_DEFAULT_BIN = "IMPORT_SITE_INFO_DEFAULT_BIN",
  IMPORT_SITE_INFO_MAPPING_PST = "IMPORT_SITE_INFO_MAPPING_PST",
}

export interface IGetFileTemplateParams {
  fileType?: FileTemplateType;
}

export interface IGetFileTemplateResponse {
  fileUrl: string;
}

export interface IUploadFileProcessingPayload {
  file: any;
  importType: ImportFileProcessingType;
  siteId?: number;
}

export interface IUploadFileProcessingResponse extends IProcessFile {
  files?: IProcessFile[];
}

export interface IProcessFile {
  fileUrl: string;
  fileName: string;
  fileType?: UploadedFileType;
}

export interface IProcessInitialFilePayload {
  fileUrl: string;
  importType: ImportFileProcessingType | UploadedFileType;
  isSubmitOnImport?: boolean;
  siteId?: number;
}

export interface IProcessInitialFileResponse {
  processFileId: string;
}
